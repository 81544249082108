@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@/evercall/ui/styles/variables.scss";

.intro {
  background: $evercallGradient;
}

.introAnimatedTitle {
  color: #ffd43c;
  text-align: right;

  @include mqMaxWidth(417px) {
    text-align: center;
  }
}

.description {
  max-width: 1120px;
}
