@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.popover {
  display: inline;
}

.popoverContainer {
  max-height: 307px;
  overflow: auto;

  @include mqMaxWidth($screenS) {
    max-height: 200px;
  }
}

.popoverText {
  color: $actionColor;
  cursor: pointer;
  white-space: nowrap;
}
