@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@/evercall/ui/styles/variables.scss";

.sectionWrapper {
  .actionButton {
    height: 56px;
    padding: 0 30px;
    font-size: 17px;
    letter-spacing: 0.01em;
    transition: background-color 200ms;
    margin: 0;

    @include mqMaxWidth(640px) {
      width: 100%;
      height: 36px;
      font-size: 14px;
    }
  }
}

.feature {
  display: flex;
  margin-bottom: 45px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mqMaxWidth($screen640) {
    margin-bottom: 20px;
  }
}

.featureIcon {
  padding-top: 5px;

  i {
    fill: $tenantEvercallColor;
  }
}

.textBlock {
  padding-left: 20px;
}

.featureHead {
  font-family: $titleFontFamily;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: $titleColor;

  @include mqMaxWidth($screenM) {
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.36px;
  }
}

.featureText {
  font-family: $fontFamily;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: $baseTextColor;
  margin-top: 8px;
  max-width: 340px;

  @include mqMaxWidth($screenM) {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.13px;
  }
}
