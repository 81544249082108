@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/UI/Pages/Landing/Sections/variables.scss";

.buttonsDivider {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #fff;

  @include mqMaxWidth($screen640) {
    display: none;
  }
}

.actionButton {
  height: 56px;
  padding: 0 30px;
  font-size: 17px;
  letter-spacing: 0.01em;
  min-width: 210px;

  @include mqMaxWidth($screen640) {
    margin: 0;
    width: 100%;
    height: 36px;
    margin: 0;
    font-size: 14px;
  }
}

.bookDemoButton {
  margin-right: 20px;
  transition: background-color $animationTime + ms;

  @include mqMaxWidth($screen640) {
    margin: 0;
  }
}

.scheduleEventButton {
  margin-left: 20px;
  transition: background-color $animationTime + ms;

  @include mqMaxWidth($screen640) {
    margin: 10px 0 0;
  }
}
