@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.row {
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  background-color: #fff;
  transition: background-color 300ms;

  &:hover {
    background-color: $defaultBackgroundColor;
  }
}

.dialInCountry {
  display: inline-block;
  box-sizing: border-box;
  margin-right: 5px;
}

.flagIcon {
  width: 22px;
  line-height: 16px;
  margin-right: 10px;

  @include mqMaxWidth(375px) {
    margin-right: 6px;
  }
}

.dialInCountryName {
  color: $titleColor;
}

.dialInPhone {
  color: $baseTextColor;
}
